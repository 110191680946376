main {
  .routes {
    height: 100%;
    flex-flow: column;

    .navigation {
      padding: 12.5px 0 0 0;
      max-height: 60px;
    }
  }
}

.routes {
  ::-webkit-scrollbar {
    width: 20px !important;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white !important;
    border-radius: 10px !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: white !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white !important;
  }

  &.dialog {
    div[role='document'] {
      min-height: 482px;
    }
  }

  .routes-container {
    height: calc(100% - 60px);

    .routesFilterGrid {
      height: 100%;
      align-content: space-evenly;
    }
  }

  .infoRow {
    display: flex;
    text-align: center;
  }

  .infoRouteField {
    text-align: center;
    flex-grow: 1;
    padding: 10px;
    border-left: 1px solid $VHGreyLight;
    font-size: 0.8rem;

    &:first-child {
      border-left: none;
    }
  }

  .tableHolder {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;

    .tableWrapper {
      overflow-y: auto;

      &.maxHeightTableWrapper {
        height: inherit;
        min-height: 92px;
      }

      .tRow {
        &.active {
          background: $VHOrangeLight;
        }
      }
    }
  }

  .recharts-responsive-container {
    g {
      font-size: 0.7rem;
    }
    .recharts-tooltip-cursor {
      background-color: $VHGreyLight;
      opacity: 0;
    }
  }
}
