main {
  .mapboxgl-map {
    font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',
      'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
      'Segoe UI Symbol', serif;
    overflow: visible;
    height: 100%;

    .help-bar {
      position: absolute;
      bottom: 0;
      right: 0;
      height: 30px;
      width: fit-content;
      background: #fff;
      text-align: right;
      opacity: 0.8;
      display: flex;
      justify-content: flex-end;
      z-index: 4;

      .container-icon-inside {
        padding: 7px 9px;

        .icon-holder {
          margin-right: 5px;
        }
      }
    }

    .map-zoom-ctrl {
      top: 40px !important;
      right: 10px !important;
      z-index: 4;
    }

    .map-container-info {
      color: $VHGrey;
    }

    .mapContainerPinWarn {
      align-items: center;
      background: #ffe500;
      border-radius: 50%;
      bottom: -2px;
      box-shadow: 0 2px 6px 0 rgba(82, 82, 82, 0.25);
      color: #424242;
      display: flex;
      font-size: 0.5rem;
      font-weight: 700;
      height: 12px;
      justify-content: center;
      position: absolute;
      right: -5px;
      width: 12px;
      z-index: 3;

      //TODO: ONLY FOR DEMO
      display: none;
    }

    .stateIndicator {
      margin: auto;
      display: block;
      width: 12px;
      height: 12px;
      border-radius: 50%;
      background: $VHBlue;

      &.red {
        background: $VHRed;
      }

      &.grey-light {
        background: $VHGreyLight;
      }

      &.orange {
        background: $VHOrange;
      }

      &.green {
        background: $VHGreen;
      }

      &.status-green {
        background: $StatusGreen;
      }

      &.status-yellow {
        background: $StatusYellow;
      }

      &.status-orange {
        background: $StatusOrange;
      }

      &.status-red {
        background: $StatusRed;
      }

      &.status-grey {
        background: $StatusGrey;
      }
    }

    // Container popup section on the map
    .containerHover {
      position: absolute;
      z-index: 3;
      display: flex;
      flex-flow: row wrap;
      left: 50px;
      top: -35px;

      &.containerHoverTop {
        left: 12px;
        top: -6px;
        transform: translate(-50%, -100%);

        .mapLocationTooltip {
          padding: 10px;
        }
      }

      &:not(.containerHoverTop) {
        .containerSectionWrapper {
          position: relative;

          .containerSectionArrow {
            height: 36px;
            left: -25px;
            position: absolute;
            top: 2px;
            width: 50px;

            &:before {
              border: 24px solid #fff;
              border-radius: 0;
              content: '\A';
              display: inline-block;
              position: absolute;
              z-index: -1;
              right: -12px;
              top: -5px;
              transform: rotate(45deg);
            }
          }
        }
      }

      .containerHeading {
        line-height: 29px;
        background: $VHGreySuperLight;
        width: 100%;
        font-size: 0.88rem;
        padding: 0 16px;
        display: flex;
        white-space: nowrap;
        max-width: 237px;

        &.short {
          max-width: 237px;

          span {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }
      }

      .containerSectionWrapper {
        padding: 10px 16px 24px;
        display: flex;
        max-height: 334px;
        flex-flow: row nowrap;

        .containerSectionTableHolder {
          overflow-y: visible;
        }

        .containerSection {
          display: flex;
          flex-flow: column nowrap;
          width: 205px;
          justify-content: space-around;
          max-width: 237px;

          .containerBodyInfo {
            div:last-child {
              overflow: hidden;

              span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }

          &.containerSectionChart {
            border-left: 1px solid $VHGreyLight;
            padding-left: 18px;
            margin-left: 18px;
            width: 237px;
          }

          .containerBodyHeader {
            height: 55px;
            align-items: center;
            display: flex;
            position: relative;

            &.red {
              .dashProgress {
                color: $VHRed;
              }
            }

            &.orange {
              .dashProgress {
                color: $VHOrange;
              }
            }

            &.green {
              .dashProgress {
                color: $VHGreen;
              }
            }

            &.status-green {
              .dashProgress {
                color: $StatusGreen;
              }
            }

            &.status-yellow {
              .dashProgress {
                color: $StatusRed;
              }
            }

            &.status-orange {
              .dashProgress {
                color: $StatusOrange;
              }
            }

            &.status-red {
              .dashProgress {
                color: $StatusRed;
              }
            }

            &.status-grey {
              .dashProgress {
                color: $StatusGrey;
              }
            }

            .dashProgress {
              width: 40px !important;
              height: 40px !important;
              color: $VHGrey;
              margin-right: 10px;
            }

            img,
            & > svg {
              height: 22px;
              left: 10px;
              position: absolute;
              top: 17px;
              width: 20px;
            }

            span {
              font-size: 1.13rem;
            }
          }

          .containerBodyInfo {
            display: flex;
            flex-flow: row nowrap;
            justify-content: space-between;
            padding-bottom: 20px;
            font-size: 0.9rem;

            div {
              display: flex;
              flex-flow: column nowrap;

              &:last-child {
                color: $VHGrey93;
              }

              span {
                line-height: 2rem;
              }
            }
          }

          .containerChart {
            height: 100%;
          }

          button {
            img {
              width: 10px;
              margin: 0 -20px 0 20px;

              &.open {
                transform: rotate(-90deg);
              }

              &.close {
                transform: rotate(90deg);
              }
            }
          }
        }
      }
    }

    .mapLocationTooltip {
      position: relative;
      box-shadow: 0 2px 6px 0 rgba(82, 82, 82, 0.25);
      border-radius: 3px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        top: 100%;
        left: 50%;
        border-top: 10px solid #ffffff;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        margin: 0 -10px;
      }
    }

    .mapContainerPopupHeader {
      padding: 5px 15px;
      min-width: 200px;
      font-size: 14px;
      background: $VHGreySuperLight;
    }
  }
}

.containerWrapper {
  z-index: 3;
  margin-left: auto;
  margin-right: auto;

  .containerHeading {
    line-height: 29px;
    background: $VHGreySuperLight;
    width: 100%;
    font-size: 0.88rem;
    padding: 0 16px;
    display: flex;
    white-space: nowrap;

    &.short {
      max-width: 237px;

      span {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  .containerSectionWrapper {
    position: relative;
    padding: 10px 16px 24px;
    display: flex;
    max-height: 334px;
    flex-flow: row nowrap;

    .containerSectionTableHolder {
      overflow-y: auto;
    }

    .containerSection {
      display: flex;
      flex-flow: column nowrap;
      width: 205px;
      justify-content: space-around;
      max-width: 237px;

      .containerBodyInfo {
        div:last-child {
          overflow: hidden;

          span {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
      }

      &.containerSectionChart {
        border-left: 1px solid $VHGreyLight;
        padding-left: 18px;
        margin-left: 18px;
        width: 237px;
        font-size: 12px;
      }

      .containerBodyHeader {
        height: 55px;
        align-items: center;
        display: flex;
        position: relative;

        &.red {
          .dashProgress {
            color: $VHRed;
          }
        }

        &.orange {
          .dashProgress {
            color: $VHOrange;
          }
        }

        &.green {
          .dashProgress {
            color: $VHGreen;
          }
        }

        &.status-green {
          .dashProgress {
            color: $StatusGreen;
          }
        }

        &.status-yellow {
          .dashProgress {
            color: $StatusYellow;
          }
        }

        &.status-orange {
          .dashProgress {
            color: $StatusOrange;
          }
        }

        &.status-red {
          .dashProgress {
            color: $StatusRed;
          }
        }

        &.status-grey {
          .dashProgress {
            color: $StatusGrey;
          }
        }

        .dashProgress {
          width: 40px !important;
          height: 40px !important;
          color: $VHGrey;
          margin-right: 10px;
        }

        img {
          height: 22px;
          left: 10px;
          position: absolute;
          top: 10px;
          width: 20px;
        }

        span {
          font-size: 1.13rem;
        }
      }

      .containerBodyInfo {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        padding-bottom: 20px;
        font-size: 0.9rem;

        div {
          display: flex;
          flex-flow: column nowrap;

          &:last-child {
            color: $VHGrey93;
          }

          span {
            line-height: 2rem;
          }
        }
      }

      button {
        img {
          width: 10px;
          margin: 0 -20px 0 20px;

          &.open {
            transform: rotate(-90deg);
          }

          &.close {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
