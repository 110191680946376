@import url('https://fonts.googleapis.com/css?family=Lato:300,400,500,600');

$VHRed: #e25656;
$VHRedDark: #e23d3b;
$VHGreen: #389e74;
$VHOrange: #df9838;
$VHOrange05: rgba(223, 152, 56, 0.5);
$VHOrangeLight: rgba(223, 152, 56, 0.2);
$VHBlue: #024559;
$VHBlueDark: #003348;
$VHGrey: #424242;
$VHGrey93: #939393;
$VHGreyLight: rgba(42, 42, 42, 0.2);
$VHGreyLight04: rgba(42, 42, 42, 0.4);
$VHGreySuperLight: #f2f2f2;

$StatusGreen: #4fb42c;
$StatusRed: #ec6b6b;
$StatusOrange: #e58e3e;
$StatusYellow: #d9cc5d;
$StatusGrey: #313c59;
$cWhite: #ffffff;

@import 'styles.main';
@import 'styles.map';
@import 'styles.containers';
@import 'styles.dashboard';
@import 'styles.routes';
@import 'styles.customRangePicker';

.settings-popup-toggler {
  width: 100%;
  border: 0 !important;
  background-color: #f0f2f7 !important;
  border-radius: 24px !important;
  height: 47px;
  color: $VHGrey93 !important;

  .MuiButton-label {
    text-transform: none;
    justify-content: space-between;
    font-size: 1rem !important;
    flex-direction: row-reverse;
  }
}

.bf-controlbar .control-item.button {
  // removing rich text editor's button box shadow
  // It is due to the other global styles.
  box-shadow: none;
}

.error-label {
  color: #e25656 !important;
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI',
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol';
}

.settings-popup {
  .MuiPopover-paper {
    min-width: 200px;
  }

  &.settings-units {
    .MuiPopover-paper {
      width: 30%;
      min-width: 550px;

      & > .MuiGrid-root {
        padding: 10px;
      }
    }
  }

  .MuiPopover-paper {
    .MuiToggleButton-root {
      height: 38px;
    }
  }
}

.settings-control-button {
  .MuiButton-label {
    text-transform: none !important;
    justify-content: space-around;
    .MuiTypography-body1 {
      flex-grow: 1;
    }
  }
}

.with-tooltip {
  margin-top: -24px;

  .MuiInputLabel-root {
    margin-left: 30px;
  }

  .MuiInputBase-root {
    padding-left: 30px;
  }

  .MuiButton-root {
    padding-top: 2px;
    padding-bottom: 1px;
    padding-left: 30px;
  }
}

.smaller-padding {
  padding-top: 3px !important;
  padding-bottom: 1px !important;
}

.settings-tooltip {
  position: relative !important;
  top: 20px;
  padding: 0 !important;
  z-index: 5;
}

.settings-tooltip-popup {
  position: absolute;
  bottom: 45px;
  right: 35px;
  background-color: transparent;
  height: fit-content;
  z-index: 100;

  .settings-tooltip-text {
    display: inline-block;
    text-align: left;
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12),
      0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
    height: auto;
    margin: 20px;
    margin-bottom: 300px;
    margin-right: 280px;
    padding: 40px;
    min-width: 275px;
    max-width: 486px;
  }

  .settings-tooltip-text::after {
    float: right;
    background-color: transparent;
    content: '\00a0';
    -webkit-box-shadow: 2px 15px 10px -5px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 2px 15px 10px -5px rgba(0, 0, 0, 0.2);
    box-shadow: 2px 15px 10px -5px rgba(0, 0, 0, 0.2);
    display: block;
    height: 20px;
    width: 20px;
    right: 260px;
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 0 0 20px;
    border-color: transparent transparent transparent #ffffff;
  }

  .settings-tooltip-close {
    color: black;
    float: right;
    position: relative;
    top: -30px;
    right: -35px;
    height: 24px;
    width: 24px;
  }

  img {
    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.tutorial-mascot-dialogue {
  display: inline-block;
  text-align: left;
  background-color: #ffffff;
  border-radius: 5px;
  box-shadow: 0 3px 14px 2px rgba(0, 0, 0, 0.12),
    0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14);
  height: auto;
  width: auto;
  padding: 40px;
  min-width: 275px;
}

.tutorial-mascot-dialogue::after {
  float: right;
  background-color: transparent;
  content: '\00a0';
  -webkit-box-shadow: 2px 15px 10px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 15px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 2px 15px 10px -5px rgba(0, 0, 0, 0.2);
  display: block;
  height: 20px;
  width: 20px;
  right: -19px;
  bottom: 20px;
  position: absolute;
  border-style: solid;
  border-width: 20px 0 0 20px;
  border-color: transparent transparent transparent #ffffff;
}

.tutorial-no-mask {
  opacity: 0;
}

.masked-link {
  text-decoration: none;
  color: inherit;
}

.b-rad-0 {
  border-radius: 0 !important;

  .MuiInputBase-root {
    border-radius: 0 !important;
  }
}

.b-t-0 {
  border-top: 0 !important;
}

.b-l-0 {
  border-left: 0 !important;
}

.b-r-0 {
  border-right: 0 !important;
}

.b-b-0 {
  border-bottom: 0 !important;
}

.p-16 {
  padding: 16px !important;
}

.b-b-gray {
  border-bottom: 1px solid $VHGrey93 !important;
}

.p-0 {
  padding: 0 !important;
}
.p-t-0 {
  padding-top: 0 !important;
}
.p-b-0 {
  padding-bottom: 0 !important;
}
.p-l-0 {
  padding-left: 0 !important;
}
.p-r-0 {
  padding-right: 0 !important;
}

@for $i from 1 to 11 {
  .p-#{$i*5} {
    padding: #{$i * 5}px !important;
  }
  .p-t-#{$i*5} {
    padding-top: #{$i * 5}px !important;
  }
  .p-b-#{$i*5} {
    padding-bottom: #{$i * 5}px !important;
  }
  .p-l-#{$i*5} {
    padding-left: #{$i * 5}px !important;
  }
  .p-r-#{$i*5} {
    padding-right: #{$i * 5}px !important;
  }
  .m-#{$i*5} {
    margin: #{$i * 5}px !important;
  }
  .m-t-#{$i*5} {
    margin-top: #{$i * 5}px !important;
  }
  .m-b-#{$i*5} {
    margin-bottom: #{$i * 5}px !important;
  }
  .m-l-#{$i*5} {
    margin-left: #{$i * 5}px !important;
  }
  .m-r-#{$i*5} {
    margin-right: #{$i * 5}px !important;
  }
  .h-#{$i*10} {
    height: #{$i * 10%} !important;
  }
  .w-#{$i*10} {
    width: #{$i * 10%} !important;
  }
  .h-min-#{$i*10} {
    min-height: #{$i * 10%} !important;
  }
  .h-min-#{$i*10}-vh {
    min-height: #{$i * 10vh} !important;
  }
  .h-max-#{$i*10} {
    max-height: #{$i * 10%} !important;
  }
  .h-max-#{$i*10}-vh {
    max-height: #{$i * 10vh} !important;
  }
  .w-min-#{$i*10} {
    min-width: #{$i * 10%} !important;
  }
  .w-max-#{$i*10} {
    max-width: #{$i * 10%} !important;
  }
}

.h-min-400 {
  min-height: 400px;
}

.w-min-600 {
  min-width: 600px;
}

.z-index-5 {
  z-index: 5 !important;
}

.block-align-left {
  margin-left: auto !important;
}

.block-align-center {
  display: block !important;
  margin-right: auto !important;
  margin-left: auto !important;
}

.MuiTextField-root > .MuiFormLabel-root {
  z-index: 1;
}

.clickable {
  cursor: pointer;
}

.hidden {
  display: none !important;
}

.flex-grow-1 {
  flex-grow: 1;
}

.center {
  margin: 0 auto !important;
}

svg {
  &.inactive {
    fill: $VHGrey93;
  }
}

.searchIcon {
  top: 10px;
  right: 10px;
  position: absolute;
}

.pinIcon {
  width: 33px;
  height: 100%;
}

.position-relative {
  position: relative !important;
}

html {
  .recharts-wrapper {
    .recharts-tooltip-wrapper {
      .recharts-default-tooltip {
        align-items: baseline;
        flex-direction: row;

        & li,
        & p {
          font-size: 0.67rem;
          line-height: 1rem;
        }
      }
    }
  }
}

.photo-upload-container {
  flex-wrap: nowrap;
  @media only screen and (min-width: 500px) {
    max-width: calc(25% - 16px) !important;
    flex-basis: calc(25% - 16px) !important;
    margin-right: 16px !important;
    margin-bottom: 16px !important;
  }
  @media only screen and (max-width: 500px) {
    height: 116px !important;
    flex-grow: 0;
    max-width: calc(33.3% - 8px) !important;
    flex-basis: calc(33.3% - 8px) !important;
    margin-right: 8px !important;
    margin-bottom: 8px !important;
    span {
      font-size: 9px;
    }
    .makeStyles-photoPlaceholder-18 {
      width: 40px;
      height: 32px;
    }
    .img {
      height: 100%;
      width: 100%;
    }
  }
}

.upload-photo-text {
  text-align: center;
  @media only screen and (max-width: 768px) {
    margin-top: 80px;
    margin-bottom: 41px;
  }
  @media only screen and (max-width: 500px) {
    margin-top: 44px;
    margin-bottom: 40px;
  }
}

#_hj_feedback_container {
  ._hj-1O2Rr__MinimizedWidgetMiddle__container {
    transition: 1s all ease-out;
    top: -70px !important;
    transform: none;

    &:hover {
      top: 0 !important;
    }
  }

  ._hj-XVgG4__ExpandedWidget__outerContainer {
    top: 150px !important;
  }
}

.upload-photo-button {
  @media only screen and (min-width: 375px) {
    margin-bottom: 75px;
  }
  @media only screen and (max-width: 375px) {
    margin-bottom: 48px;
  }
}

.no-permission-text {
  font-weight: 400;
  line-height: 1.5rem;
  color: #9aa8ad;
  font-size: 1rem;
  padding: 0 16px 16px 16px;
}

.text-align-center {
  text-align: center;
}
.authLayout label {
  color: white !important;
}
