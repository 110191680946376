.containers {
  position: relative;

  /* Track */
  ::-webkit-scrollbar {
    display: none;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px white !important;
    border-radius: 10px !important;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: white !important;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: white !important;
  }

  .stateIndicator {
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 50%;
  }

  .tableWrapper {
    overflow-x: auto;
  }

  .fullHeightCol {
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    position: relative;
    z-index: 2;

    &.listCol {
      box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: column;
      z-index: 3;
      background-color: white;
      opacity: 1;
      width: 30%;
    }
  }

  .settingsCol {
    padding: 20px;
    position: absolute;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    z-index: 3;
    background-color: #fff;
    opacity: 1;
    min-width: 500px;
    width: 30%;
    right: 0;
  }
}

div.containers div.map-zoom-ctrl {
  top: 15px !important;
}
