.dashboard {
  .searchWrap {
    position: absolute;
    z-index: 1;
    top: 3rem;

    .searchField {
      margin-left: 1rem;

      .searchParent {
        background-color: white;
      }
    }
  }

  .piechart-section {
    .piechart-label {
      margin-top: -12%;
      display: block;
    }
  }
}
